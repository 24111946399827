import { Controller as BaseController } from "@hotwired/stimulus"

export class Controller extends BaseController {
  static targets = [ "content", "swap" ]
  static values = {
    toggled: { type: Boolean, default: false },
  }

  connect() {
    this.toggledValueChanged()
  }

  toggle() {
    const input = this.swapTarget.querySelector("input")
    this.toggledValue = input.checked
  }

  toggledValueChanged() {
    if (this.toggledValue) {
      this.contentTarget.classList.remove("hidden")
    } else {
      this.contentTarget.classList.add("hidden")
    }
  }
}
