import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["password"]

  toggle() {
    const passwordFields = this.passwordTargets

    passwordFields.forEach((field) => {
      if (field.type == "password") {
        field.type = "text"
      } else {
        field.type = "password"
      }
    });
  }
}
