import { Controller as BaseController } from "@hotwired/stimulus"
import { useHover } from 'stimulus-use'

export class Controller extends BaseController {
  connect() {
    const duration = this.element.dataset.duration || 3000
    this.setState = setTimeout(() => {
      this.element.dataset.state = "closed"
    }, duration)
    this.removeElement = setTimeout(() => {
      this.element.remove()
    }, duration + 300)
    useHover(this, { element: this.element });
  }

  mouseEnter() {
    if (this.removeElement && this.setState) {
      clearTimeout(this.setState)
      clearTimeout(this.removeElement)
    }
  }
}
