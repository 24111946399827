import { Controller as BaseController } from "@hotwired/stimulus";
import SlimSelect from "slim-select"

export class Controller extends BaseController {
  static values = {
    addable: Boolean
  }

  connect() {
    document.addEventListener("turbo:render", this.addSlimSelect.bind(this))
    this.addSlimSelect()
  }

  addSlimSelect() {
    const options = {
      select: this.element,
      settings: {
        maxValuesShown: 80,
        allowDeselect: true
      }
    }

    if (this.hasAddableValue && this.addableValue) {
      options.events = {
        addable: function(value) {
          return {
            text: value,
            value: value
          }
        }
      }
    }

    this.slim = new SlimSelect(options)
  }

  destroy() {
    this.slim.destroy()
    document.removeEventListener("turbo:render", this.addSlimSelect.bind(this))
  }
}
