import { Controller as BaseController } from "@hotwired/stimulus"

export class Controller extends BaseController {
  static targets = [ "input", "checkbox" ]

  handleChange() {
    if (this._isUnchecked) {
      this.inputTarget.value = ""
    }
  }

  _isUnchecked() {
    !this.checkboxTarget.checked
  }
}
