import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["popup"]

  connect() {
    const ua = window.navigator.userAgent;
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    const webkit = !!ua.match(/WebKit/i);
    const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

    if (iOSSafari && !navigator.standalone) {
      let popupPreference = this.getCookie("pwa_popup")

      if (popupPreference != "closed") {
        this.popupTarget.classList.remove("hidden")
      }
    }
  }

  close() {
    this.popupTarget.classList.add("hidden")
    document.cookie = "pwa_popup=closed;"
  }

  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
}

