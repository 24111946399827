import { Controller as BaseController } from '@hotwired/stimulus';
import BitSet from "bitset";

export class Controller extends BaseController {
  static targets = ["checkbox", "count" ]
  static values = { bitsetLength: Number }

  connect() {
    this.update()
  }

  update() {
    const currentBitSet = this.bitset

    if (currentBitSet.isEmpty()) {
      this.checkboxTargets.forEach(target => {
        target.disabled = false
        target.checked = false
      })
    } else {
      this.checkboxTargets.forEach(target => {
        const targetBitset = BitSet(target.dataset.bitset)
        const diff = targetBitset.and(currentBitSet)
        const empty = diff.isEmpty()

        // Disable if no matches
        target.disabled = empty
        target.checked = target.checked && !empty
      })
    }

    this.countTarget.textContent = `${currentBitSet.cardinality()} exact result(s)`
  }

  get bitset() {
    const fresh = new BitSet
    fresh.setRange(0, this.bitsetLengthValue - 1, 1)

    return this.checkboxTargets.reduce((bitset, target) => {
      if (target.disabled) return bitset
      if (!target.checked) return bitset

      return bitset.and(target.dataset.bitset)
    }, fresh)
  }
}
