import { Application } from "@hotwired/stimulus"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

function registerPhlexControllers() {
  const controllers = import.meta.glob(
    [
      "./../../views/**/controller.js",
    ],
    { eager: true }
  )

  for (let path in controllers) {
    let module = controllers[path]

    let name = path
      .match(/views\/(.+)\/controller\.js$/)[1]
      .replaceAll("components/", "")
      .replaceAll("_", "-")
      .replaceAll("/", "--")

    application.register(name, module.Controller)
  }
}

registerPhlexControllers()

import CollapseController from "./collapse_controller"
application.register("collapse", CollapseController)

import HiddenFieldController from "./hidden_field_controller"
application.register("hidden-field", HiddenFieldController)

import PwaPopupController from "./pwa_popup_controller"
application.register("pwa-popup", PwaPopupController)

import ServiceWorkerController from "./service_worker_controller"
application.register("service-worker", ServiceWorkerController)

import TogglePasswordController from "./toggle_password_controller"
application.register("toggle-password", TogglePasswordController)

import { Autocomplete } from "stimulus-autocomplete"
application.register("autocomplete", Autocomplete)

import Clipboard from "@stimulus-components/clipboard"
application.register("clipboard", Clipboard)
