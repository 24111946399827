// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import "@hotwired/turbo-rails"
import "../controllers/application"

import "slim-select/styles"

import "@fontsource-variable/inter"
import "@fontsource-variable/montserrat"
import "@fontsource-variable/antonio"

import "animate.css"

import "protos-stimulus"

document.addEventListener('turbo:frame-render', () => {
  const scrollToElement = document.getElementById("scroll-to");

  if (scrollToElement) {
    const targetScrollPosition = scrollToElement.offsetTop - scrollToElement.dataset.scrollOffSet;

    window.scrollTo({
      top: targetScrollPosition,
      behavior: 'smooth'
    });
  }
});
