import { Controller as BaseController } from "@hotwired/stimulus"

export class Controller extends BaseController {
  static targets = [ "input", "output" ]

  connect() {
    this.element.addEventListener("autocomplete.change", this.handleAutocomplete.bind(this))
  }

  handleAutocomplete(event) {
    const textValue = event.detail.textValue
    const separator = this.outputTarget.value ? ", " : ""
    this.outputTarget.value = this.outputTarget.value + separator + textValue
    this.inputTarget.value = ""
  }
}
