import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.inputTarget = this.element.querySelector(".collapse > input[type=radio]")
    this.collapseTarget = this.element.querySelector(".collapse")
    this.inputTarget.addEventListener("click", this.toggle.bind(this))
    this.inputTarget.addEventListener("change", this.handleChange.bind(this))
  }

  toggle() {
    if (this.inputTarget.checked) {
      this.collapseTarget.classList.toggle("collapse-close")
    }
  }

  handleChange() {
    if (this.inputTarget.checked) {
      this.collapseTarget.classList.remove("collapse-close")
    } else {
      this.collapseTarget.classList.add("collapse-close")
    }
  }
}
